<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0" v-if="serverData">
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.type_id"
              label="Type"
              :items="serverData.types"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
              :error-messages="type_idErrors"
              @blur="$v.formData.type_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.status_id"
              label="Status"
              :items="serverData.statuses"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
              :error-messages="status_idErrors"
              @blur="$v.formData.status_id.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.label"
              label="Label"
              dense
              outlined
              clearable
              :error-messages="labelErrors"
              @blur="$v.formData.label.$touch()"
            />
          </div>

          <div class="col-6 h-75px">
            <div class="d-flex align-center mt-0 pt-0">
              <h4 class="mb-0 mt-0 ps-2 me-4 pt-0 font-weight-regular">
                Visible to all place
              </h4>
              <v-switch
                v-model="formData.visible_to_all_place"
                dense
                inset
                hide-details
                class="mt-0 pt-0"
                :true-value="true"
                :false-value="false"
              />
            </div>
          </div>
          <div class="col-6 h-75px">
            <div class="d-flex align-center mt-0 pt-0">
              <h4 class="mb-0 mt-0 ps-2 me-4 pt-0 font-weight-regular">
                Active
              </h4>
              <v-switch
                v-model="formData.is_visible"
                dense
                inset
                hide-details
                class="mt-0 pt-0"
                :true-value="true"
                :false-value="false"
              />
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
// import {
//   SET_SELECTED_CLIENT,
// } from "@/core/services/store/statusReasons.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      type_id: { required },
      status_id: { required },
      label: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: [],
    formData: {
      type_id: null,
      status_id: null,
      label: "",
      is_visible: true,
      visible_to_all_place: false,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/order_management/status_reasons/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = { ...data.status };
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        ApiService.post("/order_management/status_reasons/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      this.pageLoader(true);
      ApiService.post(`/order_management/status_reasons/${actionUrl}`, {
        ...this.formData,
      })
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        type_id: null,
        status_id: null,
        label: "",
        is_visible: true,
        visible_to_all_place: false,
      };
      // this.$store.commit(SET_SELECTED_CLIENT, null);
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    type_idErrors: function () {
      return this.handleFormValidation("type_id");
    },
    status_idErrors: function () {
      return this.handleFormValidation("status_id");
    },
    labelErrors: function () {
      return this.handleFormValidation("label");
    },
  },
};
</script>
